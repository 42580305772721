
export const clone = (obj) => {
    if (null == obj || "object" != typeof obj) return obj;
    var copy = obj.constructor();
    for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }
    return copy;
  }

  export const createRoutes = (navItemsTotal, lang) => {
    let menuRoutes = []
    let tempObj = {}
    let dest = ""
    let destTemp = ""
    let urlRoute = ""
    let subItem = {}
    let menuLevelCount = 0
    let urlRouteSplit = []
    
    const createMenuRoutes = (menuItems,isMain) => {
        menuLevelCount ++
        menuItems.map((item) => {
        urlRoute = `${urlRoute}/${item['slug_'+lang]}`
        if (menuLevelCount === 1){
            subItem = {}
            isMain ? dest = `${item.slug}_${item.ID}` : dest = isMain
            destTemp = dest
            menuRoutes.push({dest, urlRoute, item, subItem})
            tempObj = clone(item)
        }
        if (menuLevelCount === 2){
            subItem = clone(tempObj)
            isMain ? dest = `${item.slug}_${item.ID}` : dest = isMain
            menuRoutes.push({dest, urlRoute, item, subItem})
        }
        if (menuLevelCount > 2){
            subItem = clone(tempObj)   
            dest = destTemp         
            menuRoutes.push({dest, urlRoute, item, subItem})
        }
        item?.child_items && createMenuRoutes(item.child_items, isMain)
        urlRouteSplit = urlRoute.split("/")
        urlRouteSplit.splice(-1)
        urlRoute = urlRouteSplit.join("/")
        return null
        })
        menuLevelCount --
    }
    
    createMenuRoutes(navItemsTotal.navItems.items,true)
    createMenuRoutes(navItemsTotal.sobreIPB.items,false)
    createMenuRoutes(navItemsTotal.menuFooter.items,false)    
     
    return menuRoutes
  }

  export const getLangRoute = (route, menuLang, langTo) => {    
    const lang = route.substring(1,3)
    const queryRoute = route.substring(3) 
    let routeTo = `/${langTo}`

    if(queryRoute) {
        const index = menuLang[lang].findIndex(menu => menu.urlRoute === queryRoute)
        if (index > 0) {routeTo = `/${langTo}${menuLang[langTo][index].urlRoute}`}        
    }

    return routeTo
  }