//Modules
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

//Components
import Nav from '../widgets/Nav/Nav'

function NotFound() {
    const navigate = useNavigate()

    useEffect(()=>{
        setTimeout(()=>{
            navigate("/")
        }, 4000)
    },[navigate])

    return(
        <>
        <div className="container-fluid sticky-top" style={{backgroundColor: "white", zIndex: "9999"}}>
            <Nav dest={false} content={false} subItem={false}/>
        </div>
        <div style={{textAlign: 'center', marginTop: '80px', marginBottom: '80px'}} className="container-fluid">
            <h1>404</h1>
            <h3>Página não encontrada.</h3>
        </div>                                   
        </>
    )
}

export default NotFound
