//Modules
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react'
import * as urlSlug from 'url-slug'

//Utils
import useFetch from "../../utils/useFetch";

const { 
        REACT_APP_IPBNEWS_URL_IMG,
        REACT_APP_CACHE_API_ROOT,
        REACT_APP_CACHE_API_ENDPOINT,
        REACT_APP_CACHE_API_DATA_ENDPOINT,
        REACT_APP_CACHE_API_DATA_IPBNEWS_ENDPOINT,
        REACT_APP_CACHE_API_DATA_NEWS_ENDPOINT
     } = process.env

const NewsCache = ({ pathname, size, lang }) => {
    
    const { data: newsCache, loading } = useFetch(`${REACT_APP_CACHE_API_ROOT}${REACT_APP_CACHE_API_ENDPOINT}${REACT_APP_CACHE_API_DATA_ENDPOINT}${REACT_APP_CACHE_API_DATA_IPBNEWS_ENDPOINT}${REACT_APP_CACHE_API_DATA_NEWS_ENDPOINT}`)

    console.log("Cache API in use!!!")

    return (
        <>
            {!loading ? 
                <>                
                    <div className="newsCardsHome">                   
                        {newsCache.slice(0,size).map((newsItem) => (                              
                            <div key={newsItem.press_noticia_id} className="card mt-sm-3">
                                
                                <Link to={"/noticias/"+urlSlug.convert(`${newsItem.titulo} ${newsItem.press_noticia_id}`)} state={{from: pathname}}>
                                    <div className="cardImg">
                                        <img src={`${REACT_APP_IPBNEWS_URL_IMG}${newsItem.foto}`} alt="Foto"/>
                                    </div>
                                    <div className="card-body">
                                        <div className="card-title">
                                            <p>{newsItem.titulo}</p>
                                        </div>                                
                                    </div>
                                </Link>
                                <div className="card-date">
                                    <span>
                                        {new Date(newsItem.data).toLocaleDateString("pt-PT", {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                        })}
                                    </span>
                                </div>
                            </div>
                        ))}                        
                    </div>                        
                    <div className="moreNews">
                        {lang === "pt" ?
                        <Link to={`/pt/noticias`}>Todas as Notícias</Link>
                        :
                        <Link to={`/en/news`}>All News</Link>}                        
                    </div>
                </>
                : 
                <div>                    
                    <Icon style={{fontSize: '3rem'}} icon="svg-spinners:ring-resize" />
                </div>                
            }
        </>        
    )
}

export default NewsCache