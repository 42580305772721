//Modules
import { useLocation, Link } from 'react-router-dom'

const Breadcrums = () => {
    const location = useLocation()
    const lang = location.pathname.substring(1,3)

    const crums = location.pathname.substring(4).split('/')
    let link = '/'+lang

    return (
        <div className='breadcrums'>
            <ul>
                <li><Link to={'/'+lang}><span className="material-icons-outlined">home</span></Link></li>
                {crums.map((crum, index) => {
                    link = `${link}/${crum}`
                    return (
                        <li key={crum}>                             
                            <span>&gt;&nbsp;&nbsp;</span> 
                            {index !== crums.length -1 ? 
                                <Link to={link}>{crum.split('-').join(' ')}</Link> :
                                <span>{crum.split('-').join(' ')}</span>
                            }
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default Breadcrums