// Modules
import { useContext } from 'react'
import { Icon } from '@iconify/react'
import { useLocation } from 'react-router-dom'

//Utilities
import useFetch from '../../utils/useFetch'
import { menuContext } from '../../utils/UtilContext'

//Variables
const { REACT_APP_CACHE_EP_MENU, REACT_APP_WP_MENU_APPS } = process.env

function TopApps() {
    const { CACHE_API_URL } = useContext(menuContext)
    const { data: topApps } = useFetch(`${CACHE_API_URL}${REACT_APP_CACHE_EP_MENU}${REACT_APP_WP_MENU_APPS}`)

    let history = useLocation()
    const lang = history.pathname.substring(1,3)

    if (!topApps) {return (<Icon icon="svg-spinners:eclipse" />)}
    const { items: apps } = topApps

    return (
        <div className='appButton'>
            <ul>
                <li className="btn-top apps">
                    <span className="material-icons">apps</span>
                    <ul className='appsList'>
                        {apps.map((app) => (
                            <li key={app.ID}><a href={app.url} target='_blank' rel="noreferrer"><div className='renderedTitle' dangerouslySetInnerHTML={{__html:app['title_'+lang]}}></div></a></li>
                        ))}
                    </ul>
                </li>
            </ul>            
        </div>
    )
}

export default TopApps
