//Modules
import { useNavigate } from "react-router-dom";

//Utils
import { getLangRoute } from "../../utils/utilFunctions"

function TopLang({ route, menuLang }) {
  const navigate = useNavigate()
  
  const changeLang = (langTo) => {
    const routeTo = getLangRoute(route, menuLang, langTo)

    navigate(routeTo)
  }

  return (
    <div className='langButton'>
        {/* <span onClick={() => changeLang("pt")}>PT</span> */}
        {/* <span onClick={() => changeLang("en")}>EN</span> */}
        <span onClick={() => window.location.href = 'https://portalold.ipb.pt/index.php/en/ipben/home'}>EN</span>
    </div>
  )
}

export default TopLang