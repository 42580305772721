//Modules
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react'

//Components
import AgendaItem from './AgendaItem';

//Utils
import useFetch from "../../utils/useFetch";

const { 
        REACT_APP_CACHE_API_ROOT,
        REACT_APP_CACHE_API_ENDPOINT,
        REACT_APP_CACHE_API_DATA_ENDPOINT,
        REACT_APP_CACHE_API_DATA_IPBNEWS_ENDPOINT,
        REACT_APP_CACHE_API_DATA_EVENTS_ENDPOINT
     } = process.env

const AgendaCache = ({ elmt, count, lang }) => {
    
    const { data: AgendaCache, loading } = useFetch(`${REACT_APP_CACHE_API_ROOT}${REACT_APP_CACHE_API_ENDPOINT}${REACT_APP_CACHE_API_DATA_ENDPOINT}${REACT_APP_CACHE_API_DATA_IPBNEWS_ENDPOINT}${REACT_APP_CACHE_API_DATA_EVENTS_ENDPOINT}`)
    console.log("Cache API in use!!!")

    let agendaFilter = (!loading || AgendaCache !== null) &&
        AgendaCache.filter((agendaItem) => {      
            let mes = new Date(agendaItem.data_inicio).toLocaleDateString("pt-PT", {
            month: "numeric"
            })            
            let ano = new Date(agendaItem.data_inicio).getFullYear()
            if ((agendaItem.destaque === "1" && ano >= new Date().getFullYear() && Number(mes) >= new Date().getMonth()+1) || count < 4) {
            count += 1
            return agendaItem
            } else {
            return false
            }
        })
    

    return (
        <>        
           {(!loading || AgendaCache !== null) ?
            <>
                 <div className="timeline" ref={elmt}>
                    <div className="timeline__wrap">
                        <div className="timeline__items">
                            {agendaFilter.slice(0).reverse().map((agendaItem) => (
                                <AgendaItem key={agendaItem.event_id} agendaItem={agendaItem} lang={lang} />
                            ))}                         
                        </div>
                    </div>
                </div>
                <div className="moreEvents">
                <Link to={`/${lang}/eventos`}>Todos os Eventos</Link>
                </div>
            </> 
            : 
            <div>
                <Icon style={{fontSize: '3rem', color: '#dc2f2f'}} icon="iconoir:db-error" />
            </div>                
           }
        </>
    )
}

export default AgendaCache