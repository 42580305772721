import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as urlSlug from 'url-slug'

const { REACT_APP_IPBNEWS_EP, REACT_APP_IPBNEWS_EVENT } = process.env

function SearchEvents({ filtroPesquisa, lang }) {
    const [events, setEvents] = useState("")

    const abortCont = new AbortController()

    const $ = window.$
    let navigate = useNavigate();

    const goToLink = (ev, to) => {
        ev.preventDefault();
        $('#searchModal').modal('hide')
        navigate(`/${lang}/eventos/${to}`)
    }

    useEffect(() => {
        const getData = async () => {     
            let resContent = await fetch(`${REACT_APP_IPBNEWS_EP}${REACT_APP_IPBNEWS_EVENT}`, { signal: abortCont.signal })            
            const dataContent = await resContent.json()
            setEvents(dataContent)     
        }
        filtroPesquisa !== "" && getData()     

        return () => abortCont.abort()
    }, [filtroPesquisa])

    let eventsSearch = events &&
        events.filter((eventsItem) => {
            return eventsItem.titulo.includes(filtroPesquisa) || (eventsItem?.categoria && eventsItem.categoria.includes(filtroPesquisa)) || (eventsItem?.estrutura && eventsItem.estrutura.includes(filtroPesquisa))
        })
        
    return (
        <>
            {events !== null && filtroPesquisa !== "" && eventsSearch.length !== 0 &&
                <div className='eventosRes'>
                    <span className='titulo'>Eventos</span>
                    <ul>                                        
                        {eventsSearch.map((eventsItem) => (
                            <li key={eventsItem.event_id}>
                                <button onClick={(event) => goToLink(event, urlSlug.convert(`${eventsItem.titulo} ${eventsItem.event_id}`))}>{eventsItem.titulo}</button>
                                <p>{eventsItem.resumo.substring(0,130)}...</p>
                            </li>
                    ))}
                    </ul>
                </div>
            }
        </>
    )
}

export default SearchEvents
