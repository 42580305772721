//Modules
import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'

//Utils
import useReturnMenuLevel from '../../utils/useReturnMenuLevel'
import { menuContext } from '../../utils/UtilContext'

//Components
import SubPageContent from './SubPageContent'
import MoreLevels from './MoreLevels'

//Variables
const { REACT_APP_WP_EP, REACT_APP_WP_EP_SEARCH_POSTS_CATEGORY, REACT_APP_WP_EP_SEARCH_TYPES } = process.env

const PageContent = ( {content, lang} ) => {
    let menuLevel = useReturnMenuLevel(content)
    const { API_URL } = useContext(menuContext)

    const [contentsCategory, setContentsCategory] = useState([])
    const [contentsPage, setContentsPage] = useState(null)
    const [loading, setLoading] = useState(true)

    const abortCont = new AbortController()

    let navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        const getData = async () => {     
            let resContent = null
            let dataContent = null
            if (content.object === "category"){                
                resContent = await fetch(`${API_URL}${REACT_APP_WP_EP}${REACT_APP_WP_EP_SEARCH_POSTS_CATEGORY}${content.object_id}`, { signal: abortCont.signal })
                dataContent = await resContent.json()
                setContentsCategory(dataContent)
                setLoading(false)
            }else{                
                const resType = await fetch(`${API_URL}${REACT_APP_WP_EP}${REACT_APP_WP_EP_SEARCH_TYPES}${content.object}`, { signal: abortCont.signal })
                const dataType = await resType.json();
                resContent = await fetch(`${API_URL}${REACT_APP_WP_EP}${dataType.rest_base}/${content.object_id}`, { signal: abortCont.signal })
                dataContent = await resContent.json()
                setContentsPage(dataContent)
                setLoading(false)
            }                                   
        }
        getData()           

    }, [content.object, content.object_id])

    contentsCategory.length > 0 && 
    contentsCategory.sort((a, b) => {
        if (a.acf?.ordem && b.acf?.ordem){            
            const orderA = parseInt(a.acf.ordem)
            const orderB = parseInt(b.acf.ordem)
    
            if (orderA < orderB) {
                return -1
            }
            if (orderA > orderB) {
                return 1
            }            
        }
        const dateA = new Date(a.date)
        const dateB = new Date(b.date)

        return dateB - dateA
    })


    return (
        <>
            <section className='content' id={`${content.slug}_${content.ID}`}>           
                {loading ? 
                <div className="container page">
                    <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-9">
                            <Icon style={{fontSize: '3rem'}} icon="svg-spinners:ring-resize" />   
                        </div>
                    </div>
                </div>
                
                :content.object === "category" ?
                    <>{contentsCategory.length > 0 ?
                        contentsCategory.map((data, index) => (             
                            <SubPageContent key={data.id} article={data} menuLevel={menuLevel} index={index} lang={lang} />                             
                        )) : null
                    }</>
                    :
                    contentsPage ?
                        <article id={`${contentsPage.slug}_${contentsPage.id}`}>
                            <div className="container page">    
                                <div className="row">
                                    <div className="col-lg-3">
                                        {menuLevel > 3 ? <button onClick={() => navigate(-1)}><span className="material-icons-outlined course-back">arrow_back</span></button> : null}
                                    </div> 
                                    <div className="col-lg-9">
                                            <div className="content position-relative">                                                            
                                                <div dangerouslySetInnerHTML={{__html:contentsPage.content.rendered}}></div>
                                            </div>                                              
                                    </div>                            
                                </div>
                            </div>                                                                  
                        </article> : null}
                {(content?.child_items && !content.acf?.hideSubMenu) ? <MoreLevels nextLevel={content.child_items} lang={lang} /> : null}
            </section>
        </>      
    )
}

export default PageContent
