//Modules
import { useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'

//Components
import Nav from '../widgets/Nav/Nav'
import PageContent from '../widgets/Page/PageContent'
import CursoDetalhe from '../widgets/GuiaECTS/CursoDetalhe'
import Agenda from '../widgets/Agenda/Agenda'
import Breadcrums from '../widgets/Page/Breadcrums'
import { EventsContextProvider } from '../utils/UtilContext'

//Utils
import useReturnMenuLevel from '../utils/useReturnMenuLevel'

const Page = ({ menu }) => {    
    let { slugCurso } = useParams()
    const { dest, subItem, item: content} = menu 

    let history = useLocation()
    const lang = history.pathname.substring(1,3)
    
    let menuLevel = useReturnMenuLevel(content)

    let bgImage = {}

    content?.acf ?
        bgImage = {
            backgroundImage: content.acf.feature_img ? 
                `url(${content.acf.feature_img})` 
                :
                subItem?.acf && subItem.acf.feature_img && `url(${subItem.acf.feature_img})`
        }
        :
        subItem?.acf &&
            (bgImage = {
                backgroundImage: subItem.acf.feature_img && `url(${subItem.acf.feature_img})` 
            })

    useEffect(() => {
        window.scroll(0,0)
    })               

    return (
        <>         
        <div className="container-fluid sticky-top nav-bb"  style={{backgroundColor: "white", zIndex: "9998"}}>    
            <Nav dest={dest} content={content} subItem={subItem}/>
        </div>
        <div className="container-fluid imgDestaque" style={bgImage}>            
            <div className='titleBar'>
                <div className="container">
                    <div className='overlayBar'></div>
                    <h1>{Object.keys(subItem).length !== 0 ? <div dangerouslySetInnerHTML={{__html:subItem['title_'+lang]}}></div> : <div dangerouslySetInnerHTML={{__html:content['title_'+lang]}}></div>}</h1>
                </div>
            </div>
        </div>  
        <div className='container'>
            <Breadcrums />                  
        </div>
            {slugCurso ?
                <CursoDetalhe slugCurso={slugCurso} />                
                :
                <>                
                <div className="container page title">
                    <div className="row">
                        <div className="col-md-3">
                        {/* {Object.keys(subItem).length !== 0 && <div className='renderedTitle' dangerouslySetInnerHTML={{__html:content.title}}></div>} */}
                        <div className='renderedTitle' dangerouslySetInnerHTML={{__html:content['title_'+lang]}}></div>
                        </div>                    
                    </div>
                </div>
                <PageContent content={content} lang={lang} />
                {menuLevel < 3 && menuLevel > 0 && 
                    <section className="agendaSection">
                        <div className="container agenda">
                            <h1 className="title d-inline-block mb-lg-5 mb-md-3">Agenda</h1>
                            <EventsContextProvider>                        
                                <Agenda />
                            </EventsContextProvider>
                        </div>
                    </section>}
                </>
            }                                             
        </>
        
    )
}

export default Page