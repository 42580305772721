import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import * as urlSlug from 'url-slug'

const AgendaItem = ({agendaItem, lang}) => {
    const dia = new Date(agendaItem.data_inicio).toLocaleDateString("pt-PT", {
        day: "2-digit"
    })
    const mes = new Date(agendaItem.data_inicio).toLocaleDateString("pt-PT", {
        month: "short"
    }).replace('.', '')

    let { pathname } = useLocation()

    return (
        <div className="timeline__item">
            <div className="timeline__content">
                <div className="evDate">
                    <span className="evDay">{dia}</span>
                    <span className="evMonth" id="evMes">{mes}</span>
                </div>
                <div className="description">
                    <Link to={lang === "pt" ?
                        "/pt/eventos/"+urlSlug.convert(`${agendaItem.titulo} ${agendaItem.event_id}`)
                        : "/en/events/"+urlSlug.convert(`${agendaItem.titulo} ${agendaItem.event_id}`)
                    } state={{from: pathname}}>{agendaItem.titulo.length > 40 ? `${agendaItem.titulo.substring(0,40)}...` : agendaItem.titulo}</Link>
                </div>
            </div>
        </div>
    )
}

export default AgendaItem
