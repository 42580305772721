import { useLocation } from 'react-router-dom'

import TopApps from './TopApps'
import TopSearch from './TopSearch'
import SobreIPB from './SobreIPB'
import TopMiniUrl from './TopMiniUrl'
import TopLang from './TopLang'



function MiniNav({ menuPT, menuEN }) {
  let history = useLocation()
  const lang = history.pathname.substring(1,3)

  const menuLang = {
    "pt": menuPT,
    "en": menuEN
  }

  return (
    <div className='miniNav'>
        <div className='container'>
            <div className='sobre-ipb' >
              <SobreIPB lang={lang} />
            </div>
            <div className='topButtons'>
              <TopLang route={history.pathname} menuLang={menuLang} />
              <div className='spacer'></div>
              <TopMiniUrl />
              <div className='spacer'></div>
              <TopApps/>
              <div className='spacer'></div>
              <TopSearch />
            </div> 
        </div>
    </div>
  )
}

export default MiniNav