//Modules
import { Link } from 'react-router-dom'
import * as urlSlug from 'url-slug'

//Components
import PaginatedItems from '../Page/PaginatedItems'

//Utils
import useFetch from "../../utils/useFetch";

//Variables
const { 
        REACT_APP_IPBNEWS_URL_IMG,
        REACT_APP_CACHE_API_ROOT,
        REACT_APP_CACHE_API_ENDPOINT,
        REACT_APP_CACHE_API_DATA_ENDPOINT,
        REACT_APP_CACHE_API_DATA_IPBNEWS_ENDPOINT,
        REACT_APP_CACHE_API_DATA_NEWS_ENDPOINT
} = process.env

function NoticiasCache({ filtroPesquisa, lang }) {
    const { data: newsCache, loading } = useFetch(`${REACT_APP_CACHE_API_ROOT}${REACT_APP_CACHE_API_ENDPOINT}${REACT_APP_CACHE_API_DATA_ENDPOINT}${REACT_APP_CACHE_API_DATA_IPBNEWS_ENDPOINT}${REACT_APP_CACHE_API_DATA_NEWS_ENDPOINT}`)
    console.log("Cache API in use!!!")

    let newsSearch = (!loading || newsCache !== null) &&
        newsCache.filter((newsItem) => {      
        return newsItem.titulo.includes(filtroPesquisa) || (newsItem?.categoria && newsItem.categoria.includes(filtroPesquisa)) || (newsItem?.estrutura && newsItem.estrutura.includes(filtroPesquisa))
        })

    return (
        <>                   
            {(!loading || newsCache !== null) ? 
                <> 
                {newsSearch.length > 0 &&
                    <div className='featureNewsCard'>
                        <Link to={lang === "pt" ?
                            "/pt/noticias/"+urlSlug.convert(`${newsSearch[0].titulo} ${newsSearch[0].press_noticia_id}`)
                            : "/en/news/"+urlSlug.convert(`${newsSearch[0].titulo} ${newsSearch[0].press_noticia_id}`)
                        }>
                            <div className='featureNewsCardContent'>
                                <p className='featureTitle'>{newsSearch[0].titulo}</p>
                                <p className='featureSumary'>{`${newsSearch[0].resumo.substring(0,150)}...`}</p>
                                <p className='featureDate'>
                                    {new Date(newsSearch[0].data).toLocaleDateString("pt-PT", {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                    })}
                                </p>
                            </div>
                            <div className="cardImg">
                                <img src={`${REACT_APP_IPBNEWS_URL_IMG}${newsSearch[0].foto}`} alt="Foto"/>
                            </div>
                        </Link>
                    </div>}
                <PaginatedItems itemsPerPage={9} items={newsSearch.slice(1)} type={lang === "pt" ? "noticias" : "news"} lang={lang}/>              
                </>
                :null}             
        </>
    )
    }

export default NoticiasCache