//Modules
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

//Components
import Nav from '../widgets/Nav/Nav'
import News from '../widgets/News/News'
import FeaturedSlider from '../widgets/FeaturedSlider'
import Agenda from '../widgets/Agenda/Agenda'
import IconBar from '../widgets/IconBar'
import { NewsContextProvider, EventsContextProvider } from '../utils/UtilContext'

const Home = () => {
    let history = useLocation()
    const lang = history.pathname.substring(1,3)

    useEffect(() => {
        window.scroll(0,0)
    }) 

    return (
        <>
            <Nav dest={false} content={false} subItem={false}/>
            <section className="heroSection">                
                <FeaturedSlider lang={lang}/>
                <IconBar />
            </section>            
            <section className="newsSection">
                <div className="container news">
                    <h1 className="title d-inline-block mb-lg-5 mb-md-3">{lang === "pt" ? "Notícias" : "News"}</h1>
                    <NewsContextProvider>                        
                        <News size={6}/>    
                    </NewsContextProvider>
                </div>                
            </section>
            <section className="agendaSection">
                <div className="container agenda">
                    <h1 className="title d-inline-block mb-lg-5 mb-md-3">{lang === "pt" ? "Agenda" : "Events"}</h1>
                    <EventsContextProvider>                        
                        <Agenda />
                    </EventsContextProvider>
                </div>
            </section>     
        </>
    )
}

export default Home