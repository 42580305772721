import React from 'react'
import { useParams, Link, useLocation } from 'react-router-dom'

import Nav from '../widgets/Nav/Nav'

import useFetch from '../utils/useFetch';

const { REACT_APP_IPBNEWS_EP, REACT_APP_IPBNEWS_EVENT, REACT_APP_IPBNEWS_URL_IMG } = process.env

function NoticiaDetalhe() {
  let { slugEvento } = useParams();
  const id = slugEvento.split('-') 

  const { data: eventItem } = useFetch(`${REACT_APP_IPBNEWS_EP}${REACT_APP_IPBNEWS_EVENT}&id=${id[id.length-1]}`)

  let history = useLocation()
  const lang = history.pathname.substring(1,3)

  return (
      <>          
        {eventItem && 
          <>
            <div className="container-fluid sticky-top nav-bb" style={{backgroundColor: "white", zIndex: "9999"}}>
              <Nav dest={false} content={false} subItem={false}/>
            </div>
            <div className="container newsItem">
              <div className="newsItemWrapper">
                <Link to={lang === "pt" ?
                  (history?.state && history.state.from === "/pt") ? "/pt" : "/pt/eventos"
                  : (history?.state && history.state.from === "/en") ? "/en" : "/en/events"
                }><span className="material-icons-outlined newsBack">arrow_back</span></Link>
                <h3>{eventItem[0].titulo}</h3>
                <span className="newsDate"> 
                  {new Date(eventItem[0].data_inicio).toLocaleDateString("pt-PT", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                  })}
                </span>
                <img className="newsItemImage" src={`${REACT_APP_IPBNEWS_URL_IMG}${eventItem[0].foto}`}
                alt="EventoImg" />
                <p className="newsResumo">{eventItem[0].resumo}</p>
                <p className="newsTexto" dangerouslySetInnerHTML={{__html: eventItem[0].texto}}></p>                
              </div>
            </div>  
          </>
          } 
      </>
  )
}

export default NoticiaDetalhe
