//Modules
import { useContext } from 'react'

//Utils
import useFetchXML from '../../utils/useFetchXML';
import useFetch from '../../utils/useFetch';
import ListaIndividual from './widgets/ListaIndividual';
import { menuContext } from '../../utils/UtilContext'

const { REACT_APP_GUIAECTS_CURSOS, REACT_APP_GUIAECTS_PT, REACT_APP_WP_EP_MENU, REACT_APP_WP_MENU_CURING, REACT_APP_GUIAECTS_EXTRA_CURRICULAR } = process.env

function ListaCursos({ article }) {    
    const { API_URL } = useContext(menuContext)
    const { data: listaCursos } = useFetchXML(`${REACT_APP_GUIAECTS_CURSOS}${REACT_APP_GUIAECTS_PT}`)
    const { data: cursosIngles } = useFetch(`${API_URL}${REACT_APP_WP_EP_MENU}${REACT_APP_WP_MENU_CURING}`)
    const { data: extraCurr } = useFetchXML(`${REACT_APP_GUIAECTS_EXTRA_CURRICULAR}${REACT_APP_GUIAECTS_PT}`)

    const cursos = {
        listaCursos: listaCursos,
        cursosIngles: cursosIngles,
        extraCurr: extraCurr
    }

    // return <ListaIndividual cursos={cursos} tipo={article.acf.tipo_formacao.split("-")} />
    return <ListaIndividual cursos={cursos} tipo={article.acf.tipo_formacao} />
}

export default ListaCursos