//Modules
import React, { useState, useCallback } from 'react'
import ReactPaginate from 'react-paginate'

//Comnponents
import Items from './Items'

function PaginatedItems({ itemsPerPage, items, type, lang}) {
    const [itemOffset, setItemOffset] = useState(0)

    const endOffset = itemOffset + itemsPerPage
    const currentItems = items.slice(itemOffset, endOffset)
    const pageCount = Math.ceil(items.length / itemsPerPage)

    const handlePageClick = useCallback((e) => {
        const newOffset = (e.selected * itemsPerPage) % items.length;
    
        setItemOffset(newOffset);
    }, [itemsPerPage, items.length]);

  return (
    <>
        <Items currentItems={currentItems} type={type} lang={lang} />
        <ReactPaginate
            breakLabel="..."
            nextLabel="Seguinte"
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            pageCount={pageCount}
            previousLabel="Anterior"
            renderOnZeroPageCount={null}
        />
    </>
  )
}

export default PaginatedItems