//Modules
import React, { useState, useContext, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Icon } from '@iconify/react'
import * as urlSlug from 'url-slug'

//Components
import Nav from '../widgets/Nav/Nav'
import PaginatedItems from '../widgets/Page/PaginatedItems'
import NoticiasCache from '../widgets/News/NoticiasCache'
// import FiltroData from '../widgets/FiltroData'

//Utils
import { newsContext } from '../utils/UtilContext'

//Variables
const { REACT_APP_IPBNEWS_URL_IMG } = process.env

function Noticias() {
  const { news, loading, isError } = useContext(newsContext)
  // const [filtroAno, setfiltroAno] = useState("")
  // const [filtroMes, setfiltroMes] = useState("")
  const [filtroPesquisa, setfiltroPesquisa] = useState("")
  // const anos = [];  
console.log(news)
  let history = useLocation()
  const lang = history.pathname.substring(1,3)

  // {news.map((noticia) => {
  //   let data = new Date(noticia.data);
  //     if (anos.indexOf(data.getFullYear()) === -1) {
  //       anos.push(data.getFullYear());
  //     }
  // })} 

  // function handleChange(ev) {    
  //   if (ev.target.name === "Ano") {
  //     setfiltroAno(ev.target.value)
  //   } else {
  //     let liElements = document.querySelector(".mesesList").getElementsByTagName("li")
  //     for (var i = 0; i < liElements.length; i++) {
  //       liElements[i].classList.value = ""
  //     }
  //     console.log(document.getElementsByName("Ano")[0].value)
  //     !document.getElementsByName("Ano")[0].value && setfiltroAno(anos[0])
  //     if (ev.target.parentElement.name === "Mes") {
  //       setfiltroMes(ev.target.value);
  //       liElements[ev.target.value ? ev.target.value : 0].classList.value = "selected"
  //     } else {
  //       if (ev.target.getAttribute("value") !== "0") {
  //         setfiltroMes(ev.target.getAttribute("value"))
  //         liElements[ev.target.getAttribute("id")].classList.value = "selected"
  //       }
  //     }
  //   }
  // }

  useEffect(() => {
    window.scroll(0,0)
  }) 

  function handlePesquisa(ev){
    setfiltroPesquisa(ev.target.value)
  }
  
  let newsSearch = ((!loading || news !== null) && Array.isArray(news)) &&
    news.filter((newsItem) => {      
      return newsItem.titulo.includes(filtroPesquisa) || (newsItem?.categoria && newsItem.categoria.includes(filtroPesquisa)) || (newsItem?.estrutura && newsItem.estrutura.includes(filtroPesquisa))
    })

  // let newsFilter = newsSearch &&
  //   newsSearch.filter((newsItem) => {      
  //   let ano = new Date(newsItem.data).getFullYear().toString();
  //   let mes = new Date(newsItem.data).toLocaleDateString("pt-PT", {
  //     month: "numeric"
  //   });
  //   if (filtroMes) {
  //     return mes === filtroMes && ano.includes(filtroAno)
  //   } else {
  //     return ano.includes(filtroAno)
  //   }
  //   })

  return (
      <>                   
        <div className="container-fluid sticky-top nav-bb"  style={{backgroundColor: "white", zIndex: "9998"}}>
          <Nav dest={false} content={false} subItem={false}/>
        </div>
        <div className="container newsListWrapper">
          <div className="topBar">
            <div className="newsTitle"><span>Notícias</span></div>
            <div className="pesquisaNews">
              <input id="pesquisaNews" type="text" placeholder="Pesquisar..." onChange={handlePesquisa}></input>
            </div>                
          </div>              

          {/* <FiltroData anos={anos} filtroAno={filtroAno} filtroMes={filtroMes} handleChange={handleChange}/> */}
          {((!loading || news !== null) && Array.isArray(news)) ? 
            <> 
              {newsSearch.length > 0 &&
                <div className='featureNewsCard'>
                  <Link to={lang === "pt" ?
                    "/pt/noticias/"+urlSlug.convert(`${newsSearch[0].titulo} ${newsSearch[0].press_noticia_id}`)
                    : "/en/news/"+urlSlug.convert(`${newsSearch[0].titulo} ${newsSearch[0].press_noticia_id}`)
                  }>
                    <div className='featureNewsCardContent'>
                      <p className='featureTitle'>{newsSearch[0].titulo}</p>
                      <p className='featureSumary'>{`${newsSearch[0].resumo.substring(0,150)}...`}</p>
                      <p className='featureDate'>
                        {new Date(newsSearch[0].data).toLocaleDateString("pt-PT", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                        })}
                      </p>
                    </div>
                    <div className="cardImg">
                      <img src={`${REACT_APP_IPBNEWS_URL_IMG}${newsSearch[0].foto}`} alt="Foto"/>
                    </div>
                  </Link>
                </div>}
              <PaginatedItems itemsPerPage={9} items={newsSearch.slice(1)} type={lang === "pt" ? "noticias" : "news"} lang={lang}/>              
            </>
            :             
            <>
              {isError || news !== null ?
                <NoticiasCache filtroPesquisa={filtroPesquisa} lang={lang} />
              :
                <div><Icon style={{fontSize: '3rem'}} icon="svg-spinners:ring-resize" /></div>
              }
            </>} 
        </div>
        
      </>
  )
}

export default Noticias
