//Modules
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import * as urlSlug from 'url-slug'

//Variables
const { REACT_APP_IPBNEWS_URL_IMG } = process.env

function Items({ currentItems, type, lang }) {

  return (
    <div className="newsCards">
        {currentItems.map((item) => ( 
            <Fragment key={type === "noticias" || type === "news" ? item.press_noticia_id : item.event_id}>                               
                <div className={type === "noticias" || type === "news" ? "card" : "card mt-sm-3"}>
                    <Link to={"/"+lang+"/"+type+"/"+urlSlug.convert(`${item.titulo} ${type === "noticias" || type === "news" ? item.press_noticia_id : item.event_id}`)}>
                        <div className="cardImg">
                            <img src={`${REACT_APP_IPBNEWS_URL_IMG}${item.foto}`} alt="Foto"/>
                        </div>
                        <div className="card-date news">
                        <span>
                            {new Date(type === "noticias" || type === "news" ? item.data : item.data_inicio).toLocaleDateString(lang === "pt" ? "pt-PT" : "en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                            })}
                        </span>
                        </div>
                        <div className="card-body newsList">
                        <span className="material-icons-outlined">east</span>
                        <div className="card-title">
                            <p>{item.titulo}</p>
                        </div>                                
                        </div>
                    </Link>
                </div>            
            </Fragment>
        ))}
    </div>
  )
}

export default Items