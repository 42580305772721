// Modules
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react'

//Utilities
import { menuContext } from '../../utils/UtilContext'

function SobreIPB({ lang }) {
    const { navItemsTotal } = useContext(menuContext)

    if (!navItemsTotal.sobreIPB) {return (<Icon icon="svg-spinners:eclipse" />)}
    const items = navItemsTotal.sobreIPB.items[0].child_items

    return (
        <div className='appButton'>
            <ul>
                {items.map((item) => (
                    <li key={item.ID} className="menu-sobre">
                        <div className='renderedTitle' dangerouslySetInnerHTML={{__html:item['title_'+lang]}}></div>
                        <ul className='sobreList'>
                            {item?.child_items.map((child) => (
                                <li key={child.ID}>
                                    {child.object === "custom" ?
                                        <a href={child.url} target="_blank" rel="noreferrer" id={`${child.slug}_${child.ID}`}>                                                                
                                            <div className='renderedTitle' dangerouslySetInnerHTML={{__html:child['title_'+lang]}}></div>
                                        </a>
                                    :
                                        <Link to={`/${lang}/${navItemsTotal.sobreIPB.items[0]['slug_'+lang]}/${item['slug_'+lang]}/${child['slug_'+lang]}`}><div className='renderedTitle' dangerouslySetInnerHTML={{__html:child['title_'+lang]}}></div></Link>}
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>            
        </div>
    )
}

export default SobreIPB